<template>
  <div>
    <!-- Controllers -->
    <div class="d-flex justify-end align-center py-4 pr-6">
      <div v-if="!eventId" class="mr-auto pl-5 py-4">
        <div class="d-flex flex-row align-center pl-16">
          <v-icon
            color="primary"
            size="16"
          >
            arrow_back
          </v-icon>
          <a @click="$router.push({ name: 'calendar-schedule' })">
            <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span><span>{{ $t('project.sections.calendar') }}</span>
          </a>
        </div>
      </div>
      <v-btn
        color="primary"
        type="submit"
        dark
        depressed
        @click.prevent="publish()"
        v-text="$t('common.publish')"
      />
    </div>
    <!-- Body -->
    <v-container class="px-9 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <v-row class="mb-8">
            <v-col>
              <h3 class="mb-4" v-text="$t('project.academy.calendar.attendeesTitle')" />
              <span v-text="$t(`project.academy.calendar.${(activity && activity.type) || type}AttendeesSubtitle`)" />
              <v-row class="mt-4">
                <v-col id="team" cols="11">
                  <v-select
                    v-model="form.team"
                    :error-messages="getFieldErrors('team')"
                    :items="teamsFormatted"
                    item-text="suffix"
                    item-value="id"
                    :label="$t('common.team')"
                    append-icon="mdi-chevron-down"
                    outlined
                    dense
                    hide-details="auto"
                    @blur="$v.form.team.$touch()"
                  />
                </v-col>
                <v-col cols="1" class="pa-0 d-flex flex-column justify-center align-start">
                  <global-tooltip
                    :text="$t('project.academy.calendar.attendeesTooltip')"
                  />
                </v-col>
              </v-row>
              <v-row v-if="isMatchEvent" id="homeOrAway" class="px-3">
                <v-radio-group
                  v-model="homeOrAway"
                  row
                  hide-details="auto"
                  :error="$v.homeOrAway.$dirty && homeOrAway === null"
                  @change="$v.homeOrAway.$touch()"
                >
                  <v-radio
                    :label="$t('project.academy.calendar.home')"
                    :value="0"
                  />
                  <v-radio
                    :label="$t('project.academy.calendar.away')"
                    :value="1"
                  />
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col id="address" cols="11">
              <h3 class="mb-4" v-text="$t('project.academy.calendar.locationTitle')" />
              <Geolocation
                :location="updatedLocation"
                :is-dirty="$v.form.address.$dirty"
                @update="data => form.address = data"
                @blur="$v.form.address.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col id="date" cols="11">
              <v-row>
                <v-col cols="12" xl="6" class="pl-0 pr-0">
                  <v-menu
                    v-model="startTimePickerOpen"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="startTime"
                        :label="$t('project.academy.calendar.startTimeTitle')"
                        :error="$v.form.date.start.$invalid"
                        outlined
                        readonly
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        class="px-3"
                        v-on="on"
                        @blur="$v.form.date.start.$touch()"
                      >
                        <template #append class="d-flex align-center">
                          <div class="d-flex align-center mr-1">
                            <span class="caption font-weight-light" v-text="projectTimezone" />
                          </div>
                          <v-icon>
                            access_time
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="startTimePickerOpen"
                      v-model="startTime"
                      format="24hr"
                      @click:minute="updateTime('start')"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12" xl="6" class="pl-0 pr-0">
                  <v-menu
                    v-model="endTimePickerOpen"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="endTime"
                        :label="$t('project.academy.calendar.endTimeTitle')"
                        :error="$v.form.date.end.$invalid"
                        append-icon="access_time"
                        outlined
                        readonly
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        class="px-3"
                        v-on="on"
                        @blur="$v.form.date.end.$touch()"
                      >
                        <template #append class="d-flex align-center">
                          <div class="d-flex align-center mr-1">
                            <span class="caption font-weight-light" v-text="projectTimezone" />
                          </div>
                          <v-icon>
                            access_time
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="endTimePickerOpen"
                      v-model="endTime"
                      format="24hr"
                      @click:minute="updateTime('end')"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mt-8">
                <v-menu
                  v-model="isDatePickerOpen"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="isoDate"
                      :label="$t('project.academy.calendar.dateTitle')"
                      append-icon="event"
                      outlined
                      readonly
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      class="px-3"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="isoDate"
                    @input="updateDate"
                  />
                </v-menu>
              </v-row>
            </v-col>
          </v-row>
          <template v-if="isDataLoaded">
            <v-row>
              <v-col cols="11" sm="6">
                <v-checkbox
                  v-model="form.periodicity.isPeriodic"
                  :label="$t('project.academy.calendar.periodicTitle')"
                  hide-details="true"
                  :disabled="!isNewEvent"
                />
              </v-col>
            </v-row>
            <v-row v-if="form.periodicity.isPeriodic" class="mb-4 ml-1">
              <v-col cols="12" sm="12">
                <v-radio-group
                  v-model="form.periodicity.interval"
                  :error-messages="getFieldErrors('periodicity.interval')"
                  class="mt-0"
                  row
                  hide-details="auto"
                  :disabled="!isNewEvent"
                >
                  <v-radio
                    :label="$t('project.academy.calendar.daily')"
                    value="day"
                  />
                  <v-radio
                    :label="$t('project.academy.calendar.weekly')"
                    value="week"
                  />
                  <v-radio
                    :label="$t('project.academy.calendar.monthly')"
                    value="month"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>
          <v-row v-if="isMatchEvent" class="mb-8">
            <v-col id="opponents" cols="11">
              <h3 class="mb-4" v-text="$t('project.academy.calendar.opponentsTitle')" />
              <span v-text="$t('project.academy.calendar.opponentsSubtitle')" />
              <v-text-field
                v-model="form.opponents"
                :error-messages="getFieldErrors('opponents')"
                :label="$t('project.academy.calendar.opponentsLabel')"
                outlined
                dense
                hide-details="auto"
                class="mt-4"
                @blur="$v.form.opponents.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col id="name" cols="12">
              <v-row>
                <v-col cols="11">
                  <h3 class="mb-4" v-text="$t('project.academy.calendar.contentTitle')" />
                  <span v-text="$t('project.academy.calendar.contentSubtitle')" />
                  <v-text-field
                    v-model="form.name"
                    :error-messages="getFieldErrors('name')"
                    :label="$t('common.title')"
                    outlined
                    dense
                    hide-details="auto"
                    class="my-6"
                    @blur="$v.form.name.$touch()"
                  />
                  <html-field
                    v-model="form.description"
                    :error-messages="getFieldErrors('description')"
                    :placeholder="$t('common.text')"
                    @blur="$v.form.description.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row
                align="center"
                justify="center"
              >
                <v-col cols="11">
                  <video-field
                    v-model="form.video"
                    :error-messages="getFieldErrors('video')"
                  />
                </v-col>
                <v-col cols="1">
                  <global-tooltip
                    :text="$t('project.editProject.tooltipVideoYoutube')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <files-field
                    v-model="form.attachments"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isMatchEvent && eventId">
            <v-col cols="11">
              <h3 class="mb-4" v-text="$t('project.academy.calendar.resultTitle')" />
              <span v-text="$t('project.academy.calendar.resultSubtitle')" />
              <v-row>
                <v-col>
                  <v-row class="d-flex flex-row align-center my-4">
                    <v-col cols="6">
                      <v-text-field
                        :value="teamsFormatted.find(team => team.id === form.team).suffix"
                        outlined
                        dense
                        disabled
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.teamScore"
                        :error-messages="getFieldErrors('teamScore')"
                        :label="$t('project.academy.calendar.resultLabel')"
                        outlined
                        dense
                        :disabled="!eventId"
                        hide-details
                        @blur="$v.form.teamScore.$touch()"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="d-flex flex-row align-center">
                    <v-col cols="6">
                      <v-text-field
                        :value="form.opponents"
                        outlined
                        dense
                        disabled
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.opponentsScore"
                        :error-messages="getFieldErrors('opponentsScore')"
                        :label="$t('project.academy.calendar.resultLabel')"
                        outlined
                        dense
                        :disabled="!eventId"
                        hide-details
                        @blur="$v.form.opponentsScore.$touch()"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex align-start justify-start">
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="disableButton"
                    block
                    depressed
                    class="my-2"
                    @click.prevent="publishAction()"
                    v-text="$t('project.academy.calendar.resultPublish')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
    <v-dialog
      :value="openPublishPeriodicActivityDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
          style="height: 80px;"
        >
          <v-row>
            <v-col>
              <v-row class="d-flex align-center justify-space-between pa-3">
                <div class="h6" v-text="$t('project.academy.calendar.editPeriodicActivity')" />
                <v-btn icon color="black" @click="openPublishPeriodicActivityDialog = false">
                  <v-icon small color="primary">
                    close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="saveType">
            <v-radio
              v-for="item in saveTypeItems"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions
          class="justify-space-around align-center"
          style="height: 120px;"
        >
          <v-btn
            color="primary"
            depressed
            min-width="12rem"
            @click="publish"
          >
            {{ $t('common.publish') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'EditEvent',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    HtmlField: () => import('@/components/formFields/HtmlField'),
    VideoField: () => import('@/components/formFields/VideoField'),
    FilesField: () => import('@/components/formFields/FilesField'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    Geolocation: () => import('@/components/formFields/Geolocation'),
  },
  mixins: [formUtils, saveDialogUtils],
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('activity/unbind')
    next()
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    type: { type: String, default: null },
    date: { type: String, default: null },
    eventId: { type: String, default: null },
  },
  data() {
    return {
      form: { },
      homeOrAway: null,
      startTimePickerOpen: false,
      endTimePickerOpen: false,
      isDatePickerOpen: false,
      startTime: null,
      endTime: null,
      isoDate: null,
      openPublishPeriodicActivityDialog: false,
      saveType: 'editOne',
      saveTypeItems: [
        { text: this.$t('project.academy.calendar.editOne'), value: 'editOne' },
        { text: this.$t('project.academy.calendar.editGroup'), value: 'editGroup' },
        { text: this.$t('project.academy.calendar.editGroupFuture'), value: 'editGroupFuture' },
      ],
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ categories: 'academy/categories' }),
    ...mapGetters({ activity: 'activity/data' }),
    ...mapGetters({ adminCurrentUser: 'user/data' }),
    ...mapGetters({ project: 'project/data' }),
    projectTimezone: ({ project }) => project?.timezone ?? '',
    isNewEvent: ({ eventId }) => !eventId,
    isMatchEvent: ({ activity, type }) => [activity?.type, type].includes('match'),
    teamsFormatted({ teams, categories }) {
      return teams
        ?.map(team => ({ ...team, suffix: `${categories.find(category => category.id === team.categoryId)?.name} - ${team.suffix}` }))
        ?.sort((a, b) => a.suffix.localeCompare(b.suffix))
    },
    updatedLocation({ form }) {
      return form?.address?.name
    },
    disableButton({ form }) {
      return !form.teamScore || !form.opponentsScore
    },
  },
  validations() {
    return {
      form: {
        team: { required },
        address: { required },
        date: {
          start: { required },
          end: { required },
        },
        opponents: { required: requiredIf(() => this.isMatchEvent) },
        name: { required },
        description: {},
        video: {},
        attachments: {},
        teamScore: {},
        opponentsScore: {},
        periodicity: {
          isPeriodic: {},
          interval: { required: requiredIf(() => this.form.periodicity.isPeriodic) },
        },
      },
      homeOrAway: { required: requiredIf(() => this.isMatchEvent) },
    }
  },
  async created() {
    const { activity, type, isNewEvent, date } = this

    if (isNewEvent) {
      await this.$store.dispatch('activity/unbind')
    }

    this.form = {
      type: activity?.type ?? type,
      team: activity?.teamsByIndex
        ? Object.values(activity.teamsByIndex).find(team => team?.id)?.id
        : activity?.userSources?.teamIds[0] ?? null,
      address: activity?.address ?? null,
      date: this?.date
        ? { start: DateTime.fromISO(date).plus({ hours: 10 }).toJSDate(), end: DateTime.fromISO(date).plus({ hours: 11 }).toJSDate() }
        : { start: activity?.date?.start.toDate(), end: activity?.date?.end?.toDate() },
      opponents: activity?.teamsByIndex ? Object.values(activity.teamsByIndex).find(team => team?.name)?.name : null,
      name: activity?.name ?? null,
      description: activity?.description ?? null,
      video: activity?.video ?? null,
      attachments: activity?.attachments ?? [],
      teamScore: activity?.teamsByIndex && Object.values(activity.teamsByIndex).find(e => e?.id)?.score,
      opponentsScore: activity?.teamsByIndex && Object.values(activity.teamsByIndex).find(e => e?.name)?.score,
      periodicity: {
        isPeriodic: activity?.periodicity?.isPeriodic ?? false,
        interval: activity?.periodicity?.interval ?? null,
      },
    }

    this.homeOrAway = activity?.teamsByIndex
      ? Object.values(activity.teamsByIndex).findIndex(team => team?.id)
      : null

    this.startTime = DateTime.fromJSDate(this.form.date.start, { zone: this.project.timezone }).toISOTime().slice(0, 5)
    this.endTime = DateTime.fromJSDate(this.form.date.end, { zone: this.project.timezone }).toISOTime().slice(0, 5)
    this.isoDate = date || DateTime.fromJSDate(this.form.date.start, { zone: this.project.timezone }).toISODate().slice(0, 10)
    this.isDataLoaded = true
  },
  methods: {
    async publish() {
      const { saveType, form, organizationId, projectId, parseForm, isNewEvent, isMatchEvent, activity, scrollToInvalidField } = this
      if (isMatchEvent) this.$v.homeOrAway.$touch()
      if (!this.isFormValid() || this.$v.homeOrAway.$invalid) {
        const [id, value] = Object
          .entries(this.$v.form.$model)
          .filter(item => ['team', 'homeOrAway', 'address', 'date', 'opponents', 'name'].includes(item[0]))
          .find(item => (this.$v.homeOrAway.$invalid) || (this.$v.form[item[0]].$invalid && !item[1]))
        scrollToInvalidField(id)
        return
      }
      if (!isNewEvent && form.periodicity?.isPeriodic && !this.openPublishPeriodicActivityDialog) {
        this.openPublishPeriodicActivityDialog = true
        return
      }
      await this.runAsync(async () => {
        if (isNewEvent) {
          const activityId = await this.$store.dispatch('activity/create', { organizationId, projectId, data: await parseForm() })
          await this.$store.dispatch('activity/bind', { organizationId, projectId, activityId })
        } else if (saveType === 'editOne') await this.$store.dispatch('activity/update', { organizationId, projectId, data: await parseForm(), activity })
        else {
          await this.$store.dispatch('activity/update', {
            organizationId,
            projectId,
            data: await parseForm(),
            activity,
            targetPeriodicGroup: true,
            targetPastPeriodicActivities: saveType === 'editGroup',
          })
        }
      })
      this.openPublishPeriodicActivityDialog = false
      this.userAgreedToLeave = true
      this.$router.push({ name: 'calendar-schedule' })
    },
    scrollToInvalidField(id) {
      const missingInput = document.getElementById(id)
      const y = missingInput.getBoundingClientRect().top - 64
      window.scroll({ top: y, behavior: 'smooth' })
    },
    async publishAction() {
      const { organizationId, projectId, eventId, parseForm } = this
      const { teamsByIndex } = await parseForm(true)
      await this.runAsync(async () => this.$store.dispatch('activity/updateScore', { organizationId, projectId, eventId, teamsByIndex }))
      this.userAgreedToLeave = true
    },
    parseForm() {
      const { form, activity, adminCurrentUser: { id } } = this
      return (async ({ team, opponents, teamScore, opponentsScore, attachments, ...data }) => ({
        ...data,
        createdBy: id,
        type: this?.type ?? activity?.type,
        attachments,
        userSources: {
          teamIds: [team],
          excludeTeamPlayers: false,
          allIds: [team, `player-${team}`], // TODO: in the future, there will be multiple teamId's (stored in userSources.teamIds). If excludeTeamPlayers === false, allIds array will have the same items as userSources.teamIds PLUS for each item, and element 'player-${teamId}', otherwise it will be a plain copy of userSources.teamIds
        },
        ...(this.isMatchEvent && ({
          teamsByIndex: {
            [this.homeOrAway]: { id: team, name: null, ...(teamScore && ({ score: +teamScore })) },
            [this.homeOrAway === 0 ? 1 : 0]: { id: null, name: opponents, ...(opponentsScore && ({ score: +opponentsScore })) },
          },
        })),
      }))(form)
    },
    updateTime(type) {
      this.form.date[type] = DateTime.fromISO(`${this.isoDate}T${this[`${type}Time`]}:00`, { zone: this.project.timezone }).toJSDate()
      this[`${type}TimePickerOpen`] = false
    },
    updateDate() {
      ['start', 'end'].forEach(type => {
        this.form.date[type] = DateTime.fromISO(`${this.isoDate}T${this[`${type}Time`]}:00`, { zone: this.project.timezone }).toJSDate()
      })
      this.isDatePickerOpen = false
    },
  },
}
</script>
